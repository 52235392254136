import "../Styles/Login.css";
import background from "../Images/log-in-background.png";
import logo from "../Images/logo.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { login, sendOtp } from "../api"; // Make sure to add your OTP API
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { BeatLoader } from "react-spinners";
import { useEffect } from "react";

function Login() {
  const [loading, setLoading] = useState(false);
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [isOtpScreen, setIsOtpScreen] = useState(false); // To switch screens
  const [otp, setOtp] = useState(""); // OTP state
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [resendTimer, setResendTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  // Validation Schema
  const Schema = yup.object().shape({
    name: yup.string().required(t("validation.name_required")),
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const type = localStorage.getItem("type");
      if (type == "tableManager") {
        navigate("/login");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  // Formik for username
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Schema,
    onSubmit: handleUsernameSubmit,
  });

  // Handle username submission
  async function handleUsernameSubmit(values) {
    setLoading(true);

    try {
      const response = await sendOtp({ username: values.name });

      if (response.data.message === "success") {
        // Store username in localStorage
        localStorage.setItem("username", values.name);

        // Switch to OTP screen
        setIsOtpScreen(true);
        setLoading(false);
        setErrorMsgModel(false);
      } else {
        setErrorMsgModel(true);
        setMsgAr("فشل في إرسال OTP");
        setMsgEn("Failed to send OTP.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error.response.status);

      if (parseInt(error.response.status) == 429) {
        setErrorMsgModel(true);
        setMsgAr("Too many requests. Please try again later");
        setMsgEn("Invalid username");
        setLoading(false);
        return;
      }
      setErrorMsgModel(true);
      setMsgAr("خطأ في اسم المستخدم");
      setMsgEn("Invalid username");
      setLoading(false);
    }
  }

  const handleResendOtp = async () => {
    if (!canResend) return;

    setLoading(true);
    setCanResend(false); // Disable resend button until the timer resets

    try {
      // Retrieve username from localStorage
      const username = localStorage.getItem("username");

      const response = await sendOtp({ username });

      if (response.data.message === "success") {
        setMsgAr("تم إرسال OTP بنجاح.");
        setMsgEn("OTP sent successfully.");
        setErrorMsgModel(false);

        // Start the resend timer again
        startResendTimer();
      } else {
        setErrorMsgModel(true);
        setMsgAr("فشل في إعادة إرسال OTP.");
        setMsgEn("Failed to resend OTP.");
      }
    } catch (error) {
      setErrorMsgModel(true);
      setMsgAr("خطأ أثناء إعادة إرسال OTP.");
      setMsgEn("Error while resending OTP.");
      setCanResend(true);
      setResendTimer(60);
    } finally {
      setLoading(false);
    }
  };

  const startResendTimer = () => {
    setResendTimer(60);
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setCanResend(true); // Enable resend button after timer ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      // Reset to state one (username screen)
      setIsOtpScreen(false); 
      window.history.pushState(null, "", window.location.href); // Prevent back navigation
    };
  
    // Push an initial state to the history stack
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);
  
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
  

  useEffect(() => {
    if (isOtpScreen) {
      startResendTimer();
    }
  }, [isOtpScreen]);

  // Handle OTP submission
  async function handleOtpSubmit(event) {
    event.preventDefault();
    setLoading(true);

    // Retrieve username from localStorage
    const username = localStorage.getItem("username");

    try {
      const response = await login({ username, otp });

      if (response.data.message === "success") {
        // Store user data in localStorage
        const userData = response.data.data.user;
        localStorage.setItem("token", response.data.data.access_token);
        localStorage.setItem("userName", userData.name);
        localStorage.setItem("userId", userData.id);
        localStorage.setItem("userPhone", userData.phone);
        localStorage.setItem("userPhoto", userData.photo);
        localStorage.setItem("isOnline", userData.isOnline);
        localStorage.setItem("isSupervisor", userData.isSupervisor);
        localStorage.setItem("type", userData.accountType);

        if (userData.accountType === "tableManager") {
          localStorage.setItem("tableBranchAr", userData.branch.arName);
          localStorage.setItem("tableBranchEn", userData.branch.enName);
          localStorage.setItem("tableBranchId", userData.branch.id);
          navigate("/wait-list");
        } else if (userData.accountType === "productionManger") {
          navigate("/quantity");
        } else if (userData.accountType === "cashier") {
          localStorage.setItem("branchId", userData.branches[0].id);
          localStorage.setItem("branch", JSON.stringify(userData.branches[0]));
          localStorage.setItem("branchArName", userData.branches[0].arName);
          localStorage.setItem("branchEnName", userData.branches[0].enName);
          localStorage.setItem("types", JSON.stringify(userData.types));
          localStorage.setItem("customerId", userData.id);
          if (userData.tableType != null) {
            localStorage.setItem("tableType", userData.tableType.name);
          }
          navigate("/dashboard");
        } else {
          navigate("/dashboard");
        }
      } else {
        setErrorMsgModel(true);
        setMsgAr("رمز OTP غير صحيح.");
        setMsgEn("Invalid OTP.");
        setLoading(false);
      }
    } catch (error) {
      setErrorMsgModel(true);
      setMsgAr("خطأ في رمز OTP");
      setMsgEn("OTP error.");
      setLoading(false);
    }
  }

  return (
    <div className="login" style={{ backgroundImage: `url(${background})` }}>
      <div className="alignLogin">
        <p>deployedddddddd  without</p>
        {!isOtpScreen ? (
          // Username Screen
          <form className="loginForm" onSubmit={formik.handleSubmit}>
            {loading && (
              <div style={{ height: "60vh" }} className="loader">
                <BeatLoader color={"#E47100"} loading={loading} size={30} />
              </div>
            )}

            {!loading && (
              <>
                <div
                  className="loginLogo"
                  style={{ backgroundImage: `url(${logo})` }}
                ></div>

                <p>{t("Login.login")}</p>

                <label>{t("Login.userName")}</label>
                <input
                  id="name"
                  style={{ width: "100%" }}
                  value={formik.values.name}
                  name="name"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    (formik.errors.name && formik.touched.name) || errorMsgModel
                      ? "invalid"
                      : null
                  }
                />
                <span>
                  {formik.errors.name && formik.touched.name
                    ? t(`${formik.errors.name}`)
                    : null}
                </span>

                <div style={{ marginTop: "2em" }}>
                  {i18n.language === "ar" ? (
                    <span className="errMsg">
                      {errorMsgModel ? msgAr : null}
                    </span>
                  ) : (
                    <span className="errMsg">
                      {errorMsgModel ? msgEn : null}
                    </span>
                  )}
                  <button type="submit">{t("Login.log-in")}</button>
                </div>
              </>
            )}
          </form>
        ) : (
          // OTP Screen
          <form className="loginForm" onSubmit={handleOtpSubmit}>
            {loading && (
              <div style={{ height: "60vh" }} className="loader">
                <BeatLoader color={"#E47100"} loading={loading} size={30} />
              </div>
            )}

            {!loading && (
              <>
                <div
                  className="loginLogo"
                  style={{ backgroundImage: `url(${logo})` }}
                ></div>

                <p>{t("Login.enterOtp")}</p>

                <label>{t("Login.otp")}</label>
                <input
                  id="otp"
                  type="number"
                  style={{ width: "100%" }}
                  value={otp}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setOtp(value);
                    }
                  }}
                  required
                />

                <label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    direction: "rtl",
                    cursor: canResend ? "pointer" : "not-allowed",
                    color: canResend ? "#000" : "#aaa",
                  }}
                  onClick={handleResendOtp}
                  onMouseEnter={(e) =>
                    canResend
                      ? (e.target.style.textDecoration = "underline")
                      : null
                  }
                  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                >
                  {canResend
                    ? "إعادة إرسال"
                    : `إعادة الإرسال بعد ${resendTimer} ثانية`}
                </label>

                <div style={{ marginTop: "2em" }}>
                  {i18n.language === "ar" ? (
                    <span className="errMsg">
                      {errorMsgModel ? msgAr : null}
                    </span>
                  ) : (
                    <span className="errMsg">
                      {errorMsgModel ? msgEn : null}
                    </span>
                  )}
                  <button type="submit">{t("Login.verifyOtp")}</button>
                </div>
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
